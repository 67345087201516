import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Assets & Misc
import { CustomLink } from '../../utils/navigation';
import { services } from '../../constants/services';
import { useLanguages } from '../../hooks/useLanguages';

export const Services = () => {
  const { path } = useLanguages();

  return (
    <section className="pad100-50-top-bottom">
      <div className="container">
        <div className="row ">
          <div className="head-section service-head other-heading">
            <div className="col-lg-3 col-md-4 col-sm-5 col-xs-12">
              <FormattedMessage id="ourServices" tagName="h3" />
            </div>
            <div className="col-lg-9 col-md-8 col-sm-7 col-xs-12">
              <div className="fnt-18 text-justify service-container">
                <p className="service-container-p">
                  <FormattedMessage id="ourServicesDescription" />
                </p>
                <p className="service-container-p">
                  <FormattedMessage id="ourServicesDescription2" />
                </p>
                <p className="service-container-p">
                  <FormattedMessage id="ourServicesDescription3" />
                </p>
              </div>
            </div>
          </div>
          {services.map(({ id, title, coverImage, icon }) => (
            <div key={id} className="col-md-4 col-sm-4 col-xs-12 marbtm50 service-list-column">
              <CustomLink to={`${path}service?id=${id}`}>
                <span className="image_hover">
                  <img data-src={coverImage} className="img-responsive zoom_img_effect lazyload" alt={title} />
                </span>
                <div className={`service-heading service-${icon}`}>
                  <FormattedMessage id={title} tagName="h5"/>
                  <span className="read-more-link">
                    <FormattedMessage id="readMore" />
                  </span>
                </div>
              </CustomLink>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
