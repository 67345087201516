import React from 'react';

// Own components
import SEO from '../components/seo';
import Layout from '../components/Layout/en';
import { Services } from '../containers/Services';

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <Services />
  </Layout>
);

export default ServicesPage;
